import React from "react";
import Button from "../../../Button/Button";

const styles = require("./Callout.module.scss");

interface Props {}

const Callout: React.FunctionComponent<Props> = () => {
	return (
		<div className={styles.Callout}>
            <h1>Build your first workflow today!</h1>
            <h4>Experience the joy of great software.</h4>

            {/*<Button className={styles.ActionButton} onClick={() => window.location.href = 'https://app.prestavi.com/signup'} theme="solid" tone="dark">*/}
            {/*    Start your free trial*/}

            {/*    <svg width="11.028" height="18.393" viewBox="0 0 11.028 18.393">*/}
            {/*        <g transform="translate(0 0)">*/}
            {/*            <g>*/}
            {/*                <path d="M1.27,18.393a1.27,1.27,0,0,1-.891-2.174L7.9,8.811.43,2.222A1.27,1.27,0,1,1,2.11.318L10.6,7.808a1.269,1.269,0,0,1,.051,1.856L2.161,18.028a1.267,1.267,0,0,1-.891.365" fill="#265dde"/>*/}
            {/*            </g>*/}
            {/*        </g>*/}
            {/*    </svg>*/}
            {/*</Button>*/}

        </div>
	);
};

export default Callout;
