import React from "react";
import Button from "../../../Button/Button";
import Callout from "../../Home/Callout/Callout";
import Footer from "../../../Footer/Footer";

const styles = require("./Workflows.module.scss");

interface Props {}

const Workflows: React.FunctionComponent<Props> = () => {
	return (
		<div className={styles.Workflows}>
		    <div className={styles.Hero}>
				<div className={styles.Content}>
					<h3>Workflows</h3>

					<div>
						<h1>Build and scale your business processes.</h1>

						<div className={styles.ShortLine}></div>
						<p>Prestavi offers the easiest and most intuitive way to visually describe your business processes. Custom fit to your organization, each step can have an assortment of options, such as assignments, access control, content and routing. </p>

						<Button className={styles.Button} onClick={() => window.location.href = "https://app.prestavi.com/signup"} theme="solid" tone="dark" color="primary" size="standard">
							Start your free trial
							<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11.028 18.393">
								<g transform="translate(0 0)">
									<path d="M1.27,18.393a1.27,1.27,0,0,1-.891-2.174L7.9,8.811.43,2.222A1.27,1.27,0,1,1,2.11.318L10.6,7.808a1.269,1.269,0,0,1,.051,1.856L2.161,18.028a1.267,1.267,0,0,1-.891.365" fill="#265dde"/>
								</g>
							</svg>

						</Button>
					</div>
				</div>

				<img src="https://cdn.prestavi.com/website/images/workflow-builder-ui.png" alt="Workflow Builder User Interface"/>
			</div>

			<div className={styles.Routing}>
				<div className={styles.Container}>
					<h1>Flexible and easy routing with conditional logic.</h1>
					<h2>All without becoming an expert in writing formulas.</h2>
					<p>Routing between elements in your workflow can be as simple as going directly from one step to the next or as complex as using multiple conditions that include variables from external data and everything in between. </p>
					<img src="https://cdn.prestavi.com/website/images/workflow-route-conditions.png" alt="Workflow route conditions"/>
					<p>Prestavi lets you route between things however makes the most sense to your process. And the best part is that you don't need to be an excel wizard or write any formulas to set up complex conditional routes.</p>
				</div>
			</div>

			<div className={styles.StepContent}>
				<div className={styles.Container}>
					<div className={styles.Headline}>
						<h1>Add a variety of content to the steps in your workflow.</h1>
						<p>Each step can have content that one or more people view or complete. Prestavi offers a variety of different content types (with more on the way) including rich text, forms, tasks, iframe, images, and formatting options like lines and extra spacers. You can also show blocks of content only when your specific conditions are met.</p>
					</div>

					<div className={styles.ContentTypes}>
						<div className={styles.ContentType} style={{backgroundColor:"#B9E5D6"}}>
							<img src="https://cdn.prestavi.com/website/images/icon-content-type-rich-text.svg" alt="Rich text content type"/>
							<p>Rich Text</p>
						</div>

						<div className={styles.ContentType} style={{backgroundColor:"#CDEDF7"}}>
							<img src="https://cdn.prestavi.com/website/images/icon-content-type-form.svg" alt="Form content type"/>
							<p>Forms</p>
						</div>

						<div className={styles.ContentType} style={{backgroundColor:"#F8DEA1"}}>
							<img src="https://cdn.prestavi.com/website/images/icon-content-type-task.svg" alt="Task content type"/>
							<p>Tasks</p>
						</div>

						<div className={styles.ContentType} style={{backgroundColor:"#F7BBBB"}}>
							<img src="https://cdn.prestavi.com/website/images/icon-content-type-media.svg" alt="Media content type"/>
							<p>Media</p>
						</div>

						<div className={styles.ContentType} style={{backgroundColor:"#BFD0F7"}}>
							<img src="https://cdn.prestavi.com/website/images/icon-content-type-embed.svg" alt="Embed content type"/>
							<p>Embed</p>
						</div>
					</div>

					<div className={styles.FormBuilder}>
						<img src="https://cdn.prestavi.com/website/images/workflow-form-builder-example.png" alt="Workflow editor form builder"/>
					</div>

				</div>
			</div>

			<div className={styles.UserAssignments}>
				<div className={styles.Container}>
					<div className={styles.Headline}>
						<h1>Powerful user assignments</h1>
						<p>User assignments are optional, but if you need them, we have you covered from every angle. You can assign one or more specific people to a step, assign everyone with a specific role, or even someone who completed a previous step.</p>
						<p>When you assign multiple people to a step, you can decide how it's distributed and how many people must complete it. </p>

					</div>
					<img src="https://cdn.prestavi.com/website/images/user-assignments.png" alt="User assignment options"/>

					<div className={styles.Distribution}>
						<h4>Distribution Options</h4>
						<div>
							<div className={styles.Option}>
								<span>One person must complete the step</span>
							</div>
							<div className={styles.Option}>
								<span>At least n people must complete the step</span>
							</div>
							<div className={styles.Option}>
								<span>Everyone selected must complete the step</span>
							</div>
							<div className={styles.Option}>
								<span>Round robin assignment</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={styles.PublicAccessWave}>

			</div>
			<div className={styles.PublicAccess}>
				<div className={styles.Container}>
					<img src="https://cdn.prestavi.com/website/images/public-share-world.svg" alt="Globe that represents the ability to share workflows publicly"/>

					<div>
						<div className={styles.Headline}>
							<h1>Share workflows externally</h1>

							<h3>Provide access to people outside of your organization for all or only specific steps.</h3>

							<p>If your process involves people outside of your organization, give them access to complete one or more specific steps with a unique job link. </p>
						</div>
						<div className={styles.ShareOptions}>
							<div className={styles.Types}>
								<h5>Customers</h5>
								<svg xmlns="http://www.w3.org/2000/svg" width="64.42" height="1.83" viewBox="0 0 64.42 1.83">
									<path d="M0,0H5.856V1.83H0ZM11.713,0h5.856V1.83H11.713ZM23.426,0h5.856V1.83H23.426ZM35.138,0h5.856V1.83H35.138ZM46.851,0h5.856V1.83H46.851ZM58.564,0H64.42V1.83H58.564Z" fill="#a0def2"/>
								</svg>
								<h5>Vendors</h5>
								<svg xmlns="http://www.w3.org/2000/svg" width="64.42" height="1.83" viewBox="0 0 64.42 1.83">
									<path d="M0,0H5.856V1.83H0ZM11.713,0h5.856V1.83H11.713ZM23.426,0h5.856V1.83H23.426ZM35.138,0h5.856V1.83H35.138ZM46.851,0h5.856V1.83H46.851ZM58.564,0H64.42V1.83H58.564Z" fill="#a0def2"/>
								</svg>

								<h5>Suppliers</h5>
							</div>
							<div className={styles.Places}>
								<div>
									<img src="https://cdn.prestavi.com/website/images/website-examples.png" alt="Add Prestavi to your website"/>
									<div className={styles.Label}>
										<span>Your Website</span>
									</div>
								</div>
								<div>
									<img src="https://cdn.prestavi.com/website/images/prestavi-hosted-page.png" alt="Add Prestavi to your website"/>
									<div className={styles.Label}>
										<span>Prestavi Hosted Page</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Callout/>
			<Footer />
		</div>
	);
};

export default Workflows;
